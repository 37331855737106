<template>
  <div class="livreurs adjust_width">
    <title_cat>Vos livreurs</title_cat>
    
    <div class="info">
        
        <div class="t">Gagnez du temps avec l'interface livreurs</div>
        <div class="p">Rendez-vous sur <a class="cc" href="https://monresto.click/livreur" target="_blank">https://monresto.click/livreur</a> avec le smartphone de vos livreurs,
          puis entrez le nom choisi ainsi que le code affiché sur son écran dans le formulaire ci-dessous.
          <a style="margin-top: 6px;" @click="qr_etat = !qr_etat" class="qr">Voir le QR Code</a>
          <VueQr v-if="qr_etat" class="qrcode" :size="300" text="https://monresto.click/livreur"></VueQr>
        </div>

    </div>
    
    <div class="ajout">
      <div class="title_form">Ajouter un livreur</div>
      <form @submit.prevent="submit" action="">
        <label>Code affiché sur l'interface</label>
        <OtpInput class="digits"
                  ref="otpInput"
                  input-classes="el-input__inner"
                  separator=""
                  :num-inputs="8"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-change="key_change"
                  @on-complete="key_complete"
        />
        
        <label>Nom du livreur</label>
        <el-input placeholder="Nom du livreur" v-model="livreur_name"></el-input>

        <button :disabled="!livreur_key || !livreur_name || livreur_name.length < 2">Ajouter</button>
      </form>
    </div>
    
    <div class="list">
      <div v-for="n in livreurs" :key="n.id" class="livreur">
        {{ n.name }} 
        <span @click="remove(n.id)">
          <svg data-v-efe40fd4="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="20" height="20" viewBox="0 0 24 24"><path data-v-efe40fd4="" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"></path></svg>
        </span></div>
    </div>

  </div>
</template>

<script>

import OtpInput from "@bachdgvn/vue-otp-input";
import VueQr from 'vue-qr'

export default {
  name: "livreurs",
  data() {
    return {
      livreurs: [],

      livreur_key: null,
      livreur_name: null,
      
      qr_etat: false
    }
  },
  components: {
    OtpInput,
    VueQr
  },
  created() {
    this.sync()
  },
  methods: {
    sync() {
      this.$http.get('/livreur/list_livreurs/' + this.$store.state.user.user.boutique).then((livreurs) => {
        this.livreurs = livreurs.data
      })
    },
    key_change() {
      this.livreur_key = null
    },
    key_complete(key) {
      this.livreur_key = key
    },
    submit() {
      this.$http.post('/livreur', {
        boutique_id: this.$store.state.user.user.boutique,
        key: this.livreur_key,
        name: this.livreur_name
      }).then(() => {
        this.$refs.otpInput.clearInput()
        this.livreur_name = null
        this.sync()
      }).catch( () => {
        this.$notify({ title: 'Certaines informations sont en double', type: 'error' })
      })
    },
    remove(id) {
      this.$http.post('/livreur/delete', {
        id: id,
        boutique_id: this.$store.state.user.user.boutique
      }).then(() => {
        this.sync()
      })
    }
  }
}
</script>

<style>
  .livreurs .digits .el-input__inner { text-align: center; padding: 0 }
  .livreurs .digits div { margin-right: 6px; }
  .livreurs .digits div:last-child{ margin-right: 0; }
</style>
<style scoped>
  
  .info { background: var(--input); padding: 16px 20px; margin-bottom: 26px; margin-top: -16px; }
  .info .qrcode { flex: 0 0 auto; width: 300px; height: 300px; margin-top: 6px; }
  .info .t { font-size: 15px; padding-bottom: 8px; }
  .info .p { font-size: 14px; line-height: 21px; color: var(--black-sub); font-weight: 400; }

  .list { margin-top: 36px; }
  .list .livreur { font-size: 14px; height: 40px; display: flex; align-items: center; border-bottom: 1px solid var(--gris); justify-content: space-between; }
  .list .livreur svg { fill: var(--black-sub); cursor: pointer; margin-right: 6px; }

  .ajout { max-width: 450px; }
  .ajout form { }
  .ajout .el-input { margin-bottom: 6px; }


  @media only screen and (min-width: 800px) {
    
  }
  
  
  
</style>